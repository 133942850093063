import { SpeedTestStatus, WixiSnap } from '@soluto-private/wixi-web-sdk';
import { atom } from 'recoil';

interface NetworkState {
  wixiSnap: WixiSnap | null;
  goId: string | null;
  healthId: string | null;
  scanState: SpeedTestStatus;
  downloadSpeed: string;
  uploadSpeed: string;
}

export const networkState = atom<NetworkState>({
  key: 'networkState',
  default: {
    wixiSnap: null,
    goId: null,
    healthId: null,
    scanState: SpeedTestStatus.none,
    downloadSpeed: '',
    uploadSpeed: '',
  },
});
