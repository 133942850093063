import React, { FC, useState } from 'react';
import { Container } from 'components/Main';
import {
  Button,
  CheckboxSelectionCard,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import QuestionTextWrapper from './questionTextWrapper';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '@soluto-private/mx-app-header';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${breakpoints.xs} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.sm} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md} {
    padding: 0px 16px;
    margin-bottom: 48px;
  }

  ${breakpoints.md2} {
    padding: 0px 48px;
    margin-top: 46px;
  }

  ${breakpoints.lg} {
    padding: 0px 48px;
    margin-top: 46px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  > label {
    width: 100%;
  }
`;

type checkboxProps = {
  key: number | string;
  label: string;
};

const checkboxes: checkboxProps[] = [
  {
    key: 1,
    label: 'Around a certain time of day',
  },
  {
    key: 2,
    label: 'When I’m in a certain room',
  },
  {
    key: 3,
    label: 'When several people are using it',
  },
  {
    key: 4,
    label: 'On a certain device',
  },
  {
    key: 5,
    label: 'It’s always slow',
  },
  {
    key: 6,
    label: 'During other times',
  },
  {
    key: 7,
    label: 'I don’t experience slow Wi-Fi',
  },
];

const Question2: FC = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState<checkboxProps['key'][]>([]);

  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => navigate(-1), // onBack func not working...
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  const onSetSelected = (key: checkboxProps['key']) => {
    if (selected.includes(key)) {
      const newSelected = selected.filter((item) => item !== key);
      setSelected(newSelected);
    } else {
      setSelected([...selected, key]);
    }
  };

  const buttonRightGroup = (
    <>
      <Button variant="outline" color="secondary">
        Skip
      </Button>
      <Button color="secondary">Continue</Button>
    </>
  );

  return (
    <Container
      currentStep={2}
      footer={{
        onBack: true,
        buttonRightGroup,
      }}
    >
      <Wrapper>
        <QuestionTextWrapper
          question={2}
          title="Do you ever experience slow Wi-Fi?"
          subtitle="Select all that apply:"
        />
        <CheckboxWrapper>
          {checkboxes.map((item) => (
            <CheckboxSelectionCard
              key={item.key}
              checked={selected.includes(item.key)}
              onChange={() => onSetSelected(item.key)}
            >
              {item.label}
            </CheckboxSelectionCard>
          ))}
        </CheckboxWrapper>
      </Wrapper>
    </Container>
  );
};

export default Question2;
