import { useRecoilState } from 'recoil';
import { RouterState, routerState } from 'states/atoms/routerState';

export const useRouterScan = () => {
  const [routerScan, setRouterScan] = useRecoilState(routerState);

  const setAnswer = <T extends keyof RouterState>(
    key: T,
    value: RouterState[T]
  ) => {
    setRouterScan((routerScan) => ({
      ...routerScan,
      [key]: value,
    }));
  };

  return {
    routerScan,
    setAnswer,
  };
};
