import React, { useEffect } from 'react';
import styled from 'styled-components';
import { updateHeader } from '@soluto-private/mx-app-header';
import { useFlow } from 'hooks/useFlow';
import { useRouterScan } from 'hooks/useRouterScan';
import { MainTitle } from 'components/Article/Results/ResultsOverviewTitle';
import { ResultsOverviewButtons } from './ResultsOverviewButtons';
import { SpeedResults } from './DeviceSpeedScore';
import { ImpactList, SupportList } from './List';
import { RoutePath } from 'RoutePath';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const ResultsOverview = () => {
  const { goTo } = useFlow();
  const { routerScan } = useRouterScan();

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      onBack: () =>
        goTo(
          routerScan.testRouterSpeed === 'yes'
            ? RoutePath.WifiQ2
            : RoutePath.RouterSpeedQ1
        ),
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
      },
    });
  }, [goTo, routerScan]);

  return (
    <Wrapper>
      <section>
        <MainTitle />
        <ResultsOverviewButtons />
      </section>
      <SpeedResults />
      <SupportList />
      <ImpactList />
    </Wrapper>
  );
};
