import React from 'react';
import styled from 'styled-components';
import { Size, breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { GettingStarted, GettingStartedProps } from 'components/Aside';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Footer, { FooterProps } from './Footer';
import FooterMobile from './FooterMobile';

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  max-width: unset;
  height: 100%;
  box-sizing: border-box;
  grid-template-rows: 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    'content'
    'sidebar'
    'buttons';

  ${breakpoints.md2} {
    grid-template-columns: 7fr 3fr;
    grid-template-areas:
      'content sidebar'
      'buttons sidebar';
  }

  ${breakpoints.lg} {
    grid-template-columns: 6fr 4fr;
  }
`;

const Content = styled.div`
  grid-area: content;
  padding: 1.5rem 1rem;

  ${breakpoints.md} {
    padding: 1.5rem;
  }

  ${breakpoints.md2} {
    padding: 3rem 1.5rem;
  }

  ${breakpoints.xl} {
    padding: 3rem;
  }
`;

const Buttons = styled.div`
  grid-area: buttons;
  z-index: 1;
  position: sticky;
  bottom: 0;
  margin-top: 3rem;
`;

const Banner = styled.div`
  padding: 1.5rem 1rem;

  ${breakpoints.md} {
    padding: 1.5rem;
  }

  ${breakpoints.md2} {
    grid-area: sidebar;
    position: sticky;
    top: 79.5px;
    align-self: start;
    height: calc(100vh - 79.5px);
    padding: 0;
  }
`;

interface ContainerProps extends GettingStartedProps {
  children?: React.ReactNode;
  footer?: FooterProps;
}

export const Container = ({
  children,
  currentStep,
  footer,
}: ContainerProps) => {
  const isDesktopSize = useMediaQuery(`(min-width: ${Size.MD}px)`);

  return (
    <Wrapper>
      <Content>{children}</Content>
      <Banner>
        <GettingStarted currentStep={currentStep} />
      </Banner>
      <Buttons>
        {Boolean(footer) && isDesktopSize && <Footer {...footer} />}
        {Boolean(footer?.buttonRightGroup) && !isDesktopSize && (
          <FooterMobile buttonGroup={footer?.buttonRightGroup} />
        )}
      </Buttons>
    </Wrapper>
  );
};
