import { atom } from 'recoil';

export interface RouterState {
  testRouterSpeed?: 'yes' | 'no';
}

export const routerState = atom<RouterState>({
  key: 'routerState',
  default: {
    testRouterSpeed: undefined,
  },
});
