import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react-v3';

const Header = styled.div`
  line-height: 120%;
  margin: 1rem auto 1.5rem;
  text-align: center;
  padding: 0 1rem;

  ${breakpoints.md2} {
    text-align: left;
    margin: 6.25rem auto 1.5rem;
  }
`;

const SubHeader = styled.div`
  line-height: 1.3;
  text-align: center;

  ${breakpoints.md2} {
    margin-top: 0.5rem;
    text-align: left;
  }
`;

export const GettingStartedHeader: FC = () => (
  <Header data-testid="Aside_Header">
    <Text as="h2" size={5} weight="feather">
      Your expert is standing&#8209;by
    </Text>
    <SubHeader>
      <Text as="h4" size={3}>
        Here&apos;s how to get started:
      </Text>
    </SubHeader>
  </Header>
);
