import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  ScanResponse,
  SpeedTestStatus,
  WixiCustomer,
} from '@soluto-private/wixi-web-sdk';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { Container } from 'components/Main';
import { NetworkScanInProgress } from 'pages/NetworkScan/components';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { useFlow } from 'hooks/useFlow';
import { RoutePath } from 'RoutePath';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const NetworkScan = () => {
  const { scanState, cancelScan, goId } = useNetworkScan();
  const { goTo } = useFlow();

  const handleBack = () => {
    cancelScan();
    goTo(RoutePath.Welcome);
  };

  const getScan = useCallback(async () => {
    if (!goId) return;
    try {
      const wixiCustomer = new WixiCustomer();
      const results: ScanResponse = await wixiCustomer.getScan({ goId });
      console.log(results);
    } catch (e) {
      console.log('Error in getScan ', e);
    }
  }, [goId]);

  const ButtonRightGroup = (
    <Button variant="outline" color="secondary" onClick={handleBack}>
      Cancel
    </Button>
  );

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      onBack: handleBack,
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isScanCompleted = scanState === SpeedTestStatus.completed;

    if (isScanCompleted) {
      getScan();
      goTo(RoutePath.TestComplete);
    }
  }, [scanState, goTo, getScan]);

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: handleBack,
          buttonRightGroup: ButtonRightGroup,
        }}
      >
        <NetworkScanInProgress />
      </Container>
    </MainContainer>
  );
};

export default NetworkScan;
