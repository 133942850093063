import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { updateHeader } from '@soluto-private/mx-app-header';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import { useNetworkScan } from 'hooks/useNetworkScan';
import LandingPage from './landing';
import { RoutePath } from 'RoutePath';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Welcome = () => {
  const { goTo } = useFlow();
  const { scanState } = useNetworkScan();

  const buttonRightGroup = (
    <>
      <Button variant="outline" color="secondary">
        Skip
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          const nextPath =
            scanState === SpeedTestStatus.completed
              ? RoutePath.TestComplete
              : RoutePath.NetworkTest;
          goTo(nextPath);
        }}
      >
        Get Started
      </Button>
    </>
  );

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      onBack: () => goTo(RoutePath.Welcome),
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
      },
    });
  }, [goTo]);

  return (
    <MainContainer>
      <Container
        currentStep={1}
        footer={{
          onBack: () => goTo(RoutePath.Welcome),
          buttonRightGroup,
        }}
      >
        <LandingPage />
      </Container>
    </MainContainer>
  );
};

export default Welcome;
