import React, { VoidFunctionComponent, useEffect } from 'react';
import { Container } from 'components/Main';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import RouterSpeedTest from './routerSpeedTest';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { SpeedTestStatus, SpeedtestType } from '@soluto-private/wixi-web-sdk';
import { usePageState } from 'hooks/usePageState';
import { useFlow } from 'hooks/useFlow';
import { RoutePath } from 'RoutePath';
import { useLocation, useNavigate } from 'react-router';

const MainContainer = styled.div`
  height: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
`;

const useRouterScan = () => {
  const { page, setRouterState } = usePageState();
  const location = useLocation();
  const navigate = useNavigate();

  const scan = useNetworkScan({
    wixiHealthConfig: {
      speedtestType: SpeedtestType.DOWNLOAD,
    },
  });

  const isDlCompleted = (() => {
    if (page.routerState?.downloadSpeed) {
      return true;
    }
    return Boolean(
      scan.downloadSpeed &&
        scan.scanState !== SpeedTestStatus.inProgressDownload
    );
  })();

  useEffect(() => {
    const isPageComplete = RoutePath.RouterSpeedCompleted === location.pathname;
    if (!isDlCompleted && isPageComplete) {
      navigate(RoutePath.RouterSpeedProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isDlCompleted]);

  return {
    isDlCompleted,
    downloadSpeed: page.routerState?.downloadSpeed || scan.downloadSpeed,
    cancelScan: scan.cancelScan,
    startScan: scan.startScan,
    setRouterState,
  };
};

const RouterScan: VoidFunctionComponent = () => {
  const { goTo } = useFlow();
  const {
    startScan,
    cancelScan,
    isDlCompleted,
    downloadSpeed,
    setRouterState,
  } = useRouterScan();

  const onStartScan = () => {
    setRouterState(null);
    cancelScan(); // cancel existing scan and reset scanner
    startScan();
  };

  useEffect(() => {
    onStartScan();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onContinue = () => {
    setRouterState({ downloadSpeed });
    cancelScan();
    goTo(RoutePath.OnlineActivitiesQ1);
  };

  const buttonRightGroup = isDlCompleted ? (
    <>
      <Button onClick={onStartScan} variant="outline" color="secondary">
        Retest
      </Button>
      <Button onClick={onContinue} color="secondary">
        Continue
      </Button>
    </>
  ) : (
    <Button onClick={() => cancelScan()} variant="outline" color="secondary">
      Cancel
    </Button>
  );

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: true,
          buttonRightGroup,
        }}
      >
        <RouterSpeedTest
          downloadSpeed={downloadSpeed}
          isDlCompleted={isDlCompleted}
        />
      </Container>
    </MainContainer>
  );
};

export default RouterScan;
