import React, { FC, useEffect, useState } from 'react';
import { Welcome } from 'components/PageHeader/Welcome';
import styled from 'styled-components';
import RouterSpeedTestContainer from './components/RouterSpeedTestContainer';

const PageContainer = styled.div`
  height: auto;
`;

const useRandomizedProgress = (isDlCompleted: boolean) => {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    const runInterval = () => {
      const timeoutFunction = () => {
        /// stop adding if reached to 95%
        if (progress === 95) {
          clearTimeout(timeout);
        } else {
          setProgress(progress + 5);
          runInterval();
        }
      };

      const delay = Math.floor(Math.random() * (5000 - 1000)) + 1000;
      timeout = setTimeout(timeoutFunction, delay);
    };

    if (!timeout) {
      runInterval();
    }

    if (isDlCompleted) {
      clearTimeout(timeout);
      setProgress(100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [progress, isDlCompleted]);

  return progress;
};

type RouterSpeedTestProps = {
  isDlCompleted: boolean;
  downloadSpeed: string;
};

const RouterSpeedTest: FC<RouterSpeedTestProps> = ({
  isDlCompleted,
  downloadSpeed,
}) => {
  const progress = useRandomizedProgress(isDlCompleted);
  const isSpeedTestDone = progress === 100;

  return (
    <PageContainer data-testid="RouterPage_Container">
      {isSpeedTestDone ? (
        <Welcome
          pageTitle="Second test complete – one more step"
          pageSubTitle="Select “Retest” to start this test again."
        />
      ) : (
        <Welcome
          pageTitle="Running the router test now..."
          pageSubTitle="Try not to move. The test may take up to 2 minutes."
        />
      )}
      <RouterSpeedTestContainer
        progress={progress}
        downloadSpeed={downloadSpeed}
      />

      {/* comment out for scroll testing */}
      {/* {Array.from(Array(100).keys()).map((i) => (
        <p key={i}>{i}</p>
      ))} */}
    </PageContainer>
  );
};

export default RouterSpeedTest;
