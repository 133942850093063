import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { RoutePath } from 'RoutePath';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import RouterSpeedTestQuestionPage from './routerSpeedTestQuestionPage';
import { useRouterScan } from 'hooks/useRouterScan';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const RouterSpeedTestQuestion = () => {
  const { goTo } = useFlow();
  const { routerScan, setAnswer } = useRouterScan();
  const [disableContinue, setDisableContinue] = useState(true);

  useEffect(() => {
    updateHeader({
      view: 'service-view',
      menu: true,
      onBack: () => goTo(RoutePath.TestComplete),
      subNavConfig: {
        showBackButton: true,
        showPartnerLogo: true,
      },
    });
  }, [goTo]);

  const buttonRightGroup = (
    <Button
      color="secondary"
      onClick={() =>
        goTo(
          routerScan.testRouterSpeed === 'no'
            ? RoutePath.Results
            : RoutePath.RouterSpeedStart
        )
      }
      disabled={disableContinue}
    >
      Continue
    </Button>
  );

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: () => goTo(RoutePath.TestComplete),
          buttonRightGroup,
        }}
      >
        <RouterSpeedTestQuestionPage
          answer={routerScan.testRouterSpeed}
          onChange={(value) => {
            setAnswer('testRouterSpeed', value);
            setDisableContinue(typeof value === 'undefined');
          }}
        />
      </Container>
    </MainContainer>
  );
};

export default RouterSpeedTestQuestion;
