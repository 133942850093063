import React from 'react';
import styled from 'styled-components';
import { RadioSelectionCard } from '@soluto-private/mx-asurion-ui-react-v3';
import { Welcome } from 'components/PageHeader/Welcome';
import { RouterState } from 'states/atoms/routerState';

type Answer = RouterState['testRouterSpeed'];

interface Props {
  answer: Answer;
  onChange: (value: Answer) => void;
}

const RouterSpeedTestQuestionPage = ({ answer, onChange }: Props) => {
  const RadioButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 3rem;
    gap: 0.75rem;
  `;

  return (
    <div data-testid="RouterSpeedTestQuestionPage_Container">
      <Welcome
        pageTitle={'Do you want to test your router speed?'}
        pageSubTitle={
          "Testing your router speed can determine if your router is working correctly and builds a baseline for your router's capacity."
        }
      />
      <RadioButtonWrapper role="radiogroup">
        <RadioSelectionCard
          name="runSpeedTest"
          onChange={() => onChange('yes')}
          tabIndex={0}
          value="yes"
          checked={answer === 'yes'}
        >
          Yes
        </RadioSelectionCard>
        <RadioSelectionCard
          name="runSpeedTest"
          onChange={() => onChange('no')}
          tabIndex={0}
          value="no"
          checked={answer === 'no'}
        >
          No
        </RadioSelectionCard>
      </RadioButtonWrapper>
    </div>
  );
};

export default RouterSpeedTestQuestionPage;
