import React, { ReactNode } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Welcome from './pages/Welcome';
import Profile from './pages/profile';
import RouterScan from './pages/routerScan';
import NetworkScan from './pages/NetworkScan';
import TestComplete from './pages/TestComplete';
import { Question1, Question2 } from './pages/questionnaires';
import ResultsOverviewPage from './pages/ResultsOverview';

import { RoutePath } from './RoutePath';
import RouterSpeedTestQuestion from './pages/routerSpeedTestQuestion';
import RouterSpeedTestIntroduction from './pages/routerSpeedTestIntroduction';

const privateRoute = (child: ReactNode) => (
  <ProtectedRoute>{child}</ProtectedRoute>
);

const AppRoutes = () => (
  <Routes>
    <Route path={RoutePath.Home} element={<Navigate to="/welcome" replace />} />
    <Route path={RoutePath.Welcome} element={<Welcome />} />
    <Route path={RoutePath.NetworkTest} element={<NetworkScan />} />
    <Route path={RoutePath.TestComplete} element={<TestComplete />} />
    <Route
      path={RoutePath.RouterSpeedQ1}
      element={<RouterSpeedTestQuestion />}
    />
    <Route path={RoutePath.Profile} element={privateRoute(<Profile />)} />
    <Route path={RoutePath.RouterSpeedProgress} element={<RouterScan />} />
    <Route path={RoutePath.RouterSpeedCompleted} element={<RouterScan />} />
    <Route path={RoutePath.OnlineActivitiesQ1} element={<Question1 />} />
    <Route path={RoutePath.WifiQ2} element={<Question2 />} />
    <Route path={RoutePath.Results} element={<ResultsOverviewPage />} />
    <Route
      path={RoutePath.RouterSpeedStart}
      element={<RouterSpeedTestIntroduction />}
    />
  </Routes>
);

export default AppRoutes;
