import React, { FC } from 'react';
import styled from 'styled-components';
import {
  fontSizes,
  fontWeights,
  Tag,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';

const Title = styled(Text)`
  &&& {
    line-height: 1.15;
    margin-bottom: 0.5rem;
    font-size: ${fontSizes[6]};
    font-weight: ${fontWeights.feather};
  }
`;

const SubTitle = styled(Text)`
  &&& {
    margin: 0.5rem 0 1.75rem;
    line-height: 1.3;
    color: #333f48;
    font-size: ${fontSizes[4]};
    font-weight: ${fontWeights.feather};
  }
`;

const CustomTag = styled(Tag)`
  &&& {
    display: inline-block;
    font-size: ${fontSizes[4]};
    font-weight: ${fontWeights.heavy};
    color: inherit;
  }
`;

export const MainTitle: FC = () => (
  <div>
    <Title forwardedAs="h2">The results are in — we’re help to help</Title>
    <SubTitle forwardedAs="h4">
      We want to take extra time to walk you through your results and guide you
      the best way we can. Call or chat with us and give the expert your
      code:&nbsp;
      <CustomTag size="regular" text="SK902" type="light" />
    </SubTitle>
  </div>
);
