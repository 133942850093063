import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { GettingStartedHeader } from './GettingStartedHeader';
import {
  breakpoints,
  ProgressStepperStep,
  ProgressStepper,
  ProgressStepperStepState,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { AsurionExperts } from './assets';

const GettingStartedContainer = styled.aside`
  grid-area: sidebar;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border: 0.0625rem solid ${colors.neutralDeep};
  border-radius: 0.5rem;

  ${breakpoints.md2} {
    background-color: ${colors.neutralBrightest};
    border: none;
    border-radius: 0;
    height: 100%;
  }
`;

const ProgressStepperWrapper = styled.div`
  margin: 0 auto;
  padding: 0 16px;

  ${breakpoints.sm} {
    max-width: 311px;
  }

  ${breakpoints.md} {
    max-width: 311px;
  }

  ${breakpoints.xlOnly} {
    max-width: 387px;
  }
`;

const CustomProgressStepper = styled(ProgressStepper)`
  h2 {
    max-width: unset;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 25rem;
  margin-top: auto;

  ${breakpoints.md2} {
    max-width: none;
  }
`;

const stepTitles: string[] = [
  'Tell us about your home internet',
  'Run the network test',
  'Receive results',
  'Consult with an expert',
];

export interface GettingStartedProps {
  currentStep: 1 | 2 | 3 | 4;
}

export const GettingStarted: FC<GettingStartedProps> = ({ currentStep }) => {
  const finalSteps: ProgressStepperStep[] = useMemo(
    () =>
      stepTitles.map((stepTitle, i) => {
        let state: ProgressStepperStepState;
        const stepNumber = i + 1;

        if (stepNumber < currentStep) state = 'complete';
        else if (stepNumber === currentStep) state = 'inprogress';
        else state = 'incomplete';

        return {
          state,
          stepTitle,
        };
      }),

    [currentStep]
  );
  return (
    <GettingStartedContainer data-testid="Aside_Container">
      <GettingStartedHeader />
      <ProgressStepperWrapper>
        <CustomProgressStepper
          isPacked
          steps={finalSteps}
          orientation="vertical"
          size="medium"
        />
      </ProgressStepperWrapper>
      <Image src={AsurionExperts} alt="Protech Experts" />
    </GettingStartedContainer>
  );
};
