import React, { VoidFunctionComponent } from 'react';
import { Container } from 'components/Main';
import styled from 'styled-components';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { updateHeader } from '@soluto-private/mx-app-header';
import { useNavigate } from 'react-router-dom';
import RouterSpeedTestIntroductionPage from './routerSpeedTestIntroductionPage';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const buttonRightGroup = (
  <>
    <Button variant="outline" color="secondary">
      Skip
    </Button>
    <Button color="secondary">Start Test</Button>
  </>
);

const RouterSpeedTestIntroduction: VoidFunctionComponent = () => {
  const navigate = useNavigate();
  updateHeader({
    view: 'service-view',
    menu: true,
    onBack: () => navigate(-1), // onBack func not working...
    subNavConfig: {
      showBackButton: true,
      showPartnerLogo: true,
    },
  });

  return (
    <MainContainer>
      <Container
        currentStep={2}
        footer={{
          onBack: true,
          buttonRightGroup,
        }}
      >
        <RouterSpeedTestIntroductionPage />
      </Container>
    </MainContainer>
  );
};

export default RouterSpeedTestIntroduction;
