import { useRecoilState } from 'recoil';
import {
  NetworkPageState,
  PageState,
  QuestionPageState,
  RouterPageState,
  pageState,
} from 'states/atoms';

type UsePageStateResponse = {
  page: PageState;
  setNetworkState: (networkPageState: NetworkPageState) => void;
  setIsRouterTest: (isRouterTest: boolean) => void;
  setRouterState: (routerPageState: RouterPageState | null) => void;
  setQuestionState: (questionPageState: QuestionPageState) => void;
};

export const usePageState = (): UsePageStateResponse => {
  const [page, setPage] = useRecoilState(pageState);

  const setNetworkState = (networkPageState: NetworkPageState) => {
    setPage((oldPage) => ({
      ...oldPage,
      networkState: networkPageState,
    }));
  };

  const setIsRouterTest = (isRouterTest: boolean) => {
    setPage((oldPage) => ({
      ...oldPage,
      isRouterTest,
    }));
  };

  const setRouterState = (routerPageState: RouterPageState | null = null) => {
    setPage((oldPage) => ({
      ...oldPage,
      routerState: routerPageState,
    }));
  };

  const setQuestionState = (questionPageState: QuestionPageState) => {
    setPage((oldPage) => ({
      ...oldPage,
      questionState: questionPageState,
    }));
  };

  return {
    page,
    setNetworkState,
    setIsRouterTest,
    setRouterState,
    setQuestionState,
  };
};
