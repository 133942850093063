import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import {
  AuthenticationState,
  getAccessToken,
  getAuthenticationState,
  redirectToLogin,
} from '@soluto-private/mx-app-authentication';
import { updateHeader } from '@soluto-private/mx-app-header';
import { storage, Key } from '@soluto-private/mx-context';
import {
  AsurionLoadingSpinner,
  GlobalStyles,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { initializeWixiSdk } from './utils/NetworkScan';
import AppRoutes from './routes';

storage.set(Key.Client, 'att'); // not sure the right way to set...

export const Root = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const authState = getAuthenticationState();
    const isLoggedIn = authState === AuthenticationState.LoggedIn;
    setLoggedIn(isLoggedIn);
    if (!isLoggedIn) {
      redirectToLogin({ redirectUri: window.location.href });
      return;
    }

    const accessToken = getAccessToken();
    initializeWixiSdk(accessToken || '');
  }, []);

  // you can override this by adding updateHeader(HeaderState) to your page.
  updateHeader({
    view: 'service-view',
    menu: true,
    title: 'Title',
    subNavConfig: {
      title: 'SubNav Title',
      showBackButton: false,
      showPartnerLogo: true,
    },
  });

  if (!loggedIn) return <AsurionLoadingSpinner />;

  return (
    <RecoilRoot>
      <GlobalStyles />
      <AppRoutes />
    </RecoilRoot>
  );
};

export default Root;
