import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  SpeedTestDelegate,
  WixiLoggerDelegate,
  WixiSnap,
  WixiSession,
  WixiSnapConfig,
} from '@soluto-private/wixi-web-sdk';
import { networkState } from 'states/atoms';
import { formatSpeed } from 'utils/NetworkScan';

export const useNetworkScan = (wixiSnapConfig?: WixiSnapConfig) => {
  const [network, setNetwork] = useRecoilState(networkState);
  const resetNetwork = useResetRecoilState(networkState);
  const { goId, wixiSnap, scanState, downloadSpeed, uploadSpeed } = network;

  return {
    goId,
    scanState,
    downloadSpeed,
    uploadSpeed,
    downloadSpeedLabel: downloadSpeed && formatSpeed(downloadSpeed).toString(),
    uploadSpeedLabel: uploadSpeed && formatSpeed(uploadSpeed).toString(),
    startScan: async () => {
      const wixiSnap = new WixiSnap(wixiSnapConfig);
      setNetwork((network) => ({
        ...network,
        wixiSnap,
      }));

      WixiSession.onHealthIdChange = (healthId) => {
        console.log('health id: ', healthId);
        setNetwork((network) => ({
          ...network,
          healthId,
        }));
      };

      WixiSession.onGoIdChange = (goId) => {
        console.log('goId: ', goId);
        setNetwork((network) => ({
          ...network,
          goId,
        }));
      };

      SpeedTestDelegate.onUploadSpeedChange = (speed) => {
        console.log('upload speed: ', speed);
        setNetwork((network) => ({
          ...network,
          uploadSpeed: speed,
        }));
      };

      SpeedTestDelegate.onDownloadSpeedChange = (speed) => {
        console.log('download speed: ', speed);
        setNetwork((network) => ({
          ...network,
          downloadSpeed: speed,
        }));
      };

      SpeedTestDelegate.onStatusChange = (status) => {
        setNetwork((network) => ({
          ...network,
          scanState: status,
        }));
      };

      WixiLoggerDelegate.onLog = (logMessage) => {
        console.log('log: ', logMessage);
      };

      await wixiSnap.snap();
    },
    cancelScan: () => {
      wixiSnap?.cancel();
      resetNetwork();
    },
  };
};
