import React, { FC } from 'react';
import styled from 'styled-components';
import { UnorderedList, Text } from '@soluto-private/mx-asurion-ui-react-v3';

const List = styled(UnorderedList)`
  &&& {
    padding-top: 0.67rem;

    ul,
    li:last-child {
      padding: 0;
    }
  }
`;

const items: string[] = [
  '4k streaming',
  'Streaming on multiple devices',
  'Gaming',
  'Uploading and downloading large files',
  'Video calling',
];

export const SupportList: FC = () => (
  <div>
    <Text size={3} weight="heavy">
      Your network should support:
    </Text>
    <List hasIcons isSpacingCompact>
      {items.length &&
        items.map((item, index) => {
          return (
            <UnorderedList.ListItem
              key={`support-list-item-${index}`}
              iconColor="#00B574"
              iconSrc="SystemAlertCheckmark"
            >
              <React.Fragment key={`support-list-item-${index}.0`}>
                {item}
              </React.Fragment>
            </UnorderedList.ListItem>
          );
        })}
    </List>
  </div>
);
