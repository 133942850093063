import {
  Card,
  Icon,
  Text,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { useNetworkScan } from 'hooks/useNetworkScan';
import React, { FC } from 'react';
import styled from 'styled-components';

const CustomCard = styled(Card)`
  border: 2px solid #37e7a7;
  border-radius: 12px;
  max-width: 471px;
  text-align: initial;
  padding: 16px;

  ${breakpoints.xs} {
    width: auto;
  }

  ${breakpoints.smOnly} {
    width: auto;
  }

  ${breakpoints.mdOnly || breakpoints.md2Only} {
    max-width: 471px;
  }
`;

const CustomText = styled(Text)`
  font-weight: 400;
  font-size: ${fontSizes[4]};
  line-height: 1.3;
  display: flex;
  text-align: left;
`;

const SpeedScores = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.25rem 2.25rem;
  max-width: 30rem;
  margin-top: 0.75rem;
`;

const SpeedScore = styled.div`
  padding: 0px;
  max-height: 4.0625rem;
  width: auto;
  text-align: center;
`;

const InternetSpeedScores = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.25rem 2.25rem;
`;

const SpeedTitle = styled(Text).attrs({ forwardedAs: 'h6' })`
  &&& {
    margin-bottom: 0.62rem;
    font-weight: ${fontWeights.heavy};
    text-align: left;
  }
`;

const Result = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const IconBadge = styled(Icon)`
  background: ${colors.neutralBrightest};
  padding: 0.19rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreDetail = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #999999;
`;

export const SpeedResults = () => {
  const { downloadSpeedLabel, uploadSpeedLabel } = useNetworkScan();

  return (
    <CustomCard
      disableSvgColorOverride
      element="div"
      maxWidth={473.13}
      onClick={() => {
        return;
      }}
      type="default"
    >
      <CustomText forwardedAs="h3" size={3}>
        Network speed results
      </CustomText>
      <SpeedScores>
        <SpeedScore>
          <SpeedTitle>Router speed</SpeedTitle>
          <Result>
            <IconBadge
              aria-hidden="true"
              size="large"
              src="Router"
              title="router-speed"
            />
            <Text size={5}>32</Text>
            <ScoreDetail>Mbps</ScoreDetail>
          </Result>
        </SpeedScore>
        <InternetSpeedScores>
          <SpeedScore>
            <SpeedTitle>Download speed</SpeedTitle>
            <Result>
              <IconBadge
                aria-hidden="true"
                size="large"
                src="ArrowDown"
                title="download-speed"
              />
              <Text size={5}>{downloadSpeedLabel || '-'}</Text>
              <ScoreDetail>Mbps</ScoreDetail>
            </Result>
          </SpeedScore>
          <SpeedScore>
            <SpeedTitle>Upload speed</SpeedTitle>
            <Result>
              <IconBadge
                aria-hidden="true"
                size="large"
                src="ArrowUp"
                title="upload-speed"
              />
              <Text size={5}>{uploadSpeedLabel || '-'}</Text>
              <ScoreDetail>Mbps</ScoreDetail>
            </Result>
          </SpeedScore>
        </InternetSpeedScores>
      </SpeedScores>
    </CustomCard>
  );
};
